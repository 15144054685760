@import "@design";

.dispatcher-report-card {
  &.night-shift {
    background-color: #b5d2e0;
    .night-shift-icon {
      // position: absolute;
      // top: 2px;
      // left: 2px;
      transform: rotate(30deg);
    }
  }
}
.header-detail {
  font-weight: bold;
  .foreman-icon {
    font-size: 20px;
  }
  &.num-assets {
    width: auto;
    height: 22px;
    padding: 0 5px;
    font-size: 13px;
    line-height: 22px;
    color: white;
    text-align: center;
    background: $green;
    border-radius: 8px;
  }
}
.report-link-btn {
  display: block;
  transition: color 0.3s ease;
  &:link,
  &:visited {
    color: black;
  }
  &:hover {
    color: $blue;
  }
}
.assigned-assets {
  position: relative;
  min-height: 300px;
  max-height: 30vh;
  overflow-y: auto;
  .draggable-assets {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    &.empty::before {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 30px;
      color: $gray-mid;
      text-align: center;
      content: 'Drop Assets Here';
      border: 2px dashed $gray-mid;
    }
  }
}
