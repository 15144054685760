@import "@design";

.available-assets ::v-deep .asset-tile {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  font-weight: 900;
  &.assigned {
    color: white;
    background-color: $gray-mid;
    border-color: white;
    &.selected {
      background-color: $blue;
    }
  }
}
