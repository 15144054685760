@import "@design";

.vue-dialog {
  color: $gray-body;
  .dialog-content {
    text-align: center;
  }

  .vue-dialog-buttons {
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 0.9rem;
    button {
      text-transform: uppercase;
      transition: background-color, 0.3s ease, color 0.3s ease;
    }
  }
}
