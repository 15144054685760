@import "@design";

.asset-tile {
  position: relative;
  font-size: 0.8em;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  border: 2px solid transparent;

  &.sortable-ghost {
    opacity: 0.3;
  }
  &.selected {
    color: white;
    background-color: $blue;
    transform: translateY(-2px);
    &.asset-hover {
      color: $blue !important;
    }
  }
  &.ghost {
    color: white;
    cursor: not-allowed;
    background-color: $gray-mid;
    border: 2px dashed white;
    opacity: 0.8;
  }
  &.processing {
    cursor: not-allowed;
    opacity: 0.3;
  }
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 8px;
    content: '';
  }

  &.type--operator {
    &::after {
      z-index: 1000;
      background-color: $navbar-red;
    }
  }

  &.type--foreman {
    &::after {
      z-index: 1000;
      background-color: $purple;
    }
  }

  .num-assignments {
    margin-right: 4px;
    line-height: 1;
    color: white;
    background-color: $orange;
    border-radius: 100% !important;
  }
  .more-icon {
    position: absolute;
    top: 0;
    right: 0;
    .v-btn--small {
      width: 23px !important;
      height: 23px;
    }
  }
}
