@import "@design";

.text-assgnts-popup {
  .v-card__title {
    .title,
    .subtitle {
      font-family: 'Avenir', sans-serif !important;
      font-weight: 500;
      color: black;
    }
    .subtitle {
      margin-top: 0.3em;
    }
  }
  .no-phone-icon {
    color: $red;
  }
  .num-assignments {
    flex: 0 0 auto;
    margin-left: 4px;
    font-size: 0.8em;
    line-height: 15px;
    color: white;
    background-color: $orange;
    border-radius: 100% !important;
  }
}
